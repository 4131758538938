import React from "react"
import { MDXProvider } from "@mdx-js/react"

import SEO from "./seo/seo"
import Navigation from "../navigation/navigation"
import Title from "./title/title"
import Image from "./image/image"
import AppImage from "./appimage/appImage"
import Link from "./link/link"
import Listing from "./listing/listing"
import NavLinkGlobal from "./link/navLinkGlobal"
import AffiliateLink from "./affiliate/affiliateLink"
import AppPromotion from "./promotion/appPromotion"

import { container } from "./layout.module.css"

const shortcodes = {
  SEO,
  Link,
  Listing,
  NavLinkGlobal,
  AffiliateLink,
  Image,
  AppImage,
  Title,
  AppPromotion,
}

const Layout = ({ children }) => {
  return (
    <div className={container}>
      <Navigation />
      <main>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </main>
    </div>
  )
}

export default Layout
