import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import Layout from "../../components/blog/layout.de";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Impressum`}</h1>
    <h2>{`Angaben gemäß § 5 TMG`}</h2>
    <p>{`Peter Getek`}<br />{`
ShapeGanic (Einzelunternehmer)`}<br />{`
Armenische Straße 8a`}<br />{`
13349 Berlin`}<br />{` `}</p>
    <h2>{`Kontakt`}</h2>
    <p>{`Telefon: +49 (0) 30 546 215 05`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:info@shapeganic.com"
      }}>{`info@shapeganic.com`}</a></p>
    <h2>{`Postadresse`}</h2>
    <p>{`ShapeGanic`}<br />{`
Peter Getek`}<br />{`
Postfach 51 02 32`}<br />{`
Berlin, Berlin 13362`}<br />{`
Deutschland`}</p>
    <h2>{`Verbraucherstreitbeilegung/Universalschlichtungsstelle`}</h2>
    <p>{`Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      