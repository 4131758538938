import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Image from "../image/image"
import NavLinkGlobal from "../link/navLinkGlobal"

export default function AppPromotion() {
  const data = useStaticQuery(graphql`
    {
      appBenefitsImage: file(
        relativePath: { eq: "en/is-there-a-free-calorie-counter.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      appBenefitsContent: file(relativePath: { eq: "app_promotion.md" }) {
        childMarkdownRemark {
          frontmatter {
            image_app_promotion_alt_en
            image_app_promotion_desc_en
            image_app_promotion_credit_text_en
            image_app_promotion_credit_link_en
          }
        }
      }
    }
  `)

  return (
    <>
      <h2>Lose Weight and Build Muscle</h2>
      <p>
        You read articles like this one because you have real goals. You want to
        achieve something, something that is important to you. You want to lose some
        weight to feel more comfortable, to be healthier, or to please yourself more.
        You want to build up muscles in order to have a slim and beautiful physique in
        the future. Supplements can be very helpful here, but at the end of the day,
        your <b>total daily calories</b> and the <b>macronutrient distribution</b>{" "}
        make the difference between <b>success and failure</b>!
      </p>
      <Image
        image={getImage(data.appBenefitsImage)}
        imageAlt={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_alt_en}
        imageDesc={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_desc_en}
        imageAuthor={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_credit_text_en}
        imageLink={data.appBenefitsContent.childMarkdownRemark.frontmatter.image_app_promotion_credit_link_en}
      />
      <h3>Total Calories and Macronutrient Distribution</h3>
      <p>
        To stay on track, you need to control your calories and keep an eye on them.
        But also the macronutrient distribution, i.e. the ratio of eaten protein,
        carbohydrates and fats, is extremely important. Determining these values daily
        by hand in a spreadsheet is very time-consuming and frustrating. To help you
        out, I'm providing you with a 100% free{" "}
        <NavLinkGlobal to="/#appstores">calorie counter app</NavLinkGlobal>. It also
        helps you to log your weight and analyze it graphically. What are you waiting
        for? <NavLinkGlobal to="/#appstores">Start NOW!</NavLinkGlobal>
      </p>
    </>
  )
}
